/* eslint-disable react-hooks/exhaustive-deps */
import { FeaturedItem } from "domain/featuredItem/types";
import { useEffect, useRef, useState } from "react";

export type FeaturedItemsProps = {
  currentItemIndex: number;
  lastItemIndex: number;
  amount: number;
  selectedItem?: FeaturedItem;
  onPreviousItem: () => void;
  onNextItem: () => void;
  getProgress: () => number;
};

const INITIAL_INDEX_STATE = 0;

export const TIMER_REPEAT_TIME = 30000;

export const useFeaturedItems = (
  featuredItems: FeaturedItem[]
): FeaturedItemsProps => {
  const [progress, setProgress] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] =
    useState(INITIAL_INDEX_STATE);
  const [selectedItem, setSelectedItem] = useState<FeaturedItem>(
    featuredItems[INITIAL_INDEX_STATE]
  );
  const isMounted = useRef(false);
  const timer = useRef<number | null>(null);
  const timerForProgress = useRef<number | null>(null);

  const lastItemIndex = useRef(featuredItems.length).current - 1;

  useEffect(() => {
    if (featuredItems.length <= 1) {
      return;
    }

    scheduleTimer();
    return () => clearTimer();
  }, []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    clearTimer();
    setTimeout(() => {
      setSelectedItem(featuredItems[selectedItemIndex]);
      scheduleTimer();
    }, 600);
  }, [selectedItemIndex]);

  const scheduleTimer = () => {
    setProgress(0);
    timer.current = window.setInterval(onNextItem, TIMER_REPEAT_TIME);
    timerForProgress.current = window.setInterval(
      () => setProgress((progress) => progress + 1),
      1000
    );
  };

  const clearTimer = () => {
    window.clearInterval(timer.current || 0);
    window.clearInterval(timerForProgress.current || 0);
  };

  const onPreviousItem = () => {
    setSelectedItemIndex((currentIndex) => {
      if (currentIndex === 0) {
        return lastItemIndex;
      }
      return currentIndex - 1;
    });
  };

  const onNextItem = () => {
    setSelectedItemIndex((currentIndex) => {
      if (currentIndex === lastItemIndex) {
        return 0;
      }
      return currentIndex + 1;
    });
  };

  const getProgress = () => {
    const miliseconds = progress * 1000;
    return (miliseconds * 100) / TIMER_REPEAT_TIME;
  };

  return {
    currentItemIndex: selectedItemIndex,
    lastItemIndex,
    selectedItem,
    amount: featuredItems.length,
    onPreviousItem,
    onNextItem,
    getProgress,
  };
};
