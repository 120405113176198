import getFeaturedCollection from "domain/collection/getFeaturedCollection";
import getOrganizationFeaturedItems from "domain/featuredItem/getOrganizationFeaturedItems";
import { withSSRDBConnection } from "lib/database";
import { GetOrganizationSSProps, withOrganization } from "lib/ssr";
import Home, { HomeTemplateProps } from "templates/Home";
import { serialize } from "utils/serialize";

export default function Index(props: HomeTemplateProps) {
  return <Home {...props} />;
}

const getIndexProps: GetOrganizationSSProps<HomeTemplateProps> = async ({
  query,
  organization,
  configuration,
}) => {
  const [{ response: featuredCollection }, featuredItems = []] =
    await Promise.all([
      getFeaturedCollection(query, organization?._id),
      getOrganizationFeaturedItems(query, organization?._id),
    ]);
  return {
    props: serialize({
      configuration,
      featuredItems,
      featuredCollection,
      stylesheetUrl: organization?.stylesheetUrl,
      browserTabText: organization?.browserTabText,
      faviconUrl: organization?.faviconUrl,
      background: organization?.background,
      logoUrl: organization?.logoUrl,
      footer: organization?.footer,
      organizationName: organization?.name,
      domain: organization?.domain,
    }),
  };
};

export const getServerSideProps = withSSRDBConnection(
  withOrganization(getIndexProps)
);
