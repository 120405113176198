import React, { ButtonHTMLAttributes } from "react";

import * as S from "./styles";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, onClick }: ButtonProps) => {
  return (
    <S.Wrapper onClick={onClick} className="button">
      {children}
    </S.Wrapper>
  );
};

export default Button;
