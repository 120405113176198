import { ArrowLeft, ArrowRight } from "@styled-icons/heroicons-outline";
import Footer from "components/Footer";
import Header from "components/Header";
import { BackgroundMediaComponent, MediaComponent } from "components/Media";
import MediaMatch from "components/MediaMatch";
import TimeCounter from "components/TimeCounter";
import { Configuration } from "domain/configuration/types";
import { FeaturedItem } from "domain/featuredItem/types";
import { Organization, OrganizationFooter } from "domain/organization/types";
import { AnimatePresence } from "framer-motion";
import { TIMER_REPEAT_TIME, useFeaturedItems } from "hooks/useFeaturedItems";
import { useWindowSize } from "hooks/useWindowSize";
import { useRouter } from "next/router";
import Script from "next/script";
import { useCallback } from "react";

import {
  fadeIn,
  fadeInAndGrow,
  slideFromLeft,
  slideFromRight,
} from "./animations";
import Button from "./Button";
import FeaturedCollection, {
  FeaturedColletionProps,
} from "./FeaturedCollection";
import * as S from "./styles";

export type HomeTemplateProps = Pick<Organization, "background"> & {
  featuredItems: FeaturedItem[];
  featuredCollection?: FeaturedColletionProps;
  logoUrl?: string;
  domain?: string;
  footer?: OrganizationFooter;
  organizationName?: string;
  configuration: Configuration;
};

const Home = ({
  featuredItems,
  featuredCollection,
  background,
  logoUrl,
  footer,
  organizationName,
  domain,
  configuration,
}: HomeTemplateProps) => {
  const {
    currentItemIndex,
    selectedItem,
    amount,
    onPreviousItem,
    onNextItem,
    getProgress,
  } = useFeaturedItems(featuredItems);
  const { push } = useRouter();

  const handleClick = useCallback(() => {
    if (selectedItem?.buttonUrl) {
      push(selectedItem?.buttonUrl);
    }
  }, [selectedItem, push]);

  const { isMobile, isSizeLoaded } = useWindowSize();
  const disableArrow = amount === 1;

  return (
    <S.PageContainer className="home">
      <S.HeroWrapper className="home__hero">
        <Header logoUrl={logoUrl} desktopColor="white" mobileColor="white" />
        <S.ContentWrapper className="home__content-wrapper">
          <AnimatePresence exitBeforeEnter={true} initial={false}>
            {selectedItem && (
              <S.GridContent
                tabIndex={0}
                key={currentItemIndex}
                className="home__inner-content"
                role="listbox"
                aria-label={`Carroussel menu with featured NFTs, you are currently on NFT ${
                  currentItemIndex + 1
                } of ${amount}. ${selectedItem.title}. Description: ${
                  selectedItem.description
                }`}
              >
                <S.ProgressBar
                  role="progressbar"
                  aria-valuenow={getProgress()}
                  aria-label="Carrousel progress bar"
                  duration={TIMER_REPEAT_TIME}
                  className="home__progress-bar"
                />
                <S.NftInfoContainer
                  {...(!isMobile && slideFromLeft)}
                  className="home__nft-info-container"
                >
                  <S.DesktopProgressBar
                    className="home__desktop-progress-bar"
                    aria-label={`NFT ${currentItemIndex + 1} of ${amount}`}
                  >
                    <span aria-hidden="true">{`0${currentItemIndex + 1}`}</span>
                    <div></div>
                    <span aria-hidden="true">{`0${amount}`}</span>
                  </S.DesktopProgressBar>
                  <S.Title {...(isMobile && fadeIn)} className="home__title">
                    {selectedItem.title}
                  </S.Title>
                  <MediaMatch greaterThan="large" className="home__media-match">
                    <TimerBox availableAt={selectedItem.availableAt} />
                  </MediaMatch>
                </S.NftInfoContainer>
                {selectedItem.objectUrl &&
                  selectedItem.objectType &&
                  selectedItem.title && (
                    <S.MediaContainer className="home__media-container">
                      <S.MediaWrapper
                        {...(!isMobile && fadeInAndGrow)}
                        {...(isMobile && fadeIn)}
                        className="home__nft-wrapper"
                      >
                        <MediaComponent
                          objectUrl={selectedItem.objectUrl}
                          objectType={selectedItem.objectType}
                          title={selectedItem.title}
                        />
                      </S.MediaWrapper>
                    </S.MediaContainer>
                  )}
                <MediaMatch
                  greaterThan="large"
                  style={{ padding: "0 3rem" }}
                  className="home__mdia-match"
                >
                  <DescriptionBox
                    shouldAnimate={!isMobile}
                    description={selectedItem.description}
                  />
                </MediaMatch>
              </S.GridContent>
            )}
          </AnimatePresence>
          <S.FooterNav className="home__hero footer">
            <S.FooterArrowButton
              className="home__footer-arrow-button"
              aria-label="Previous Nft on carroussel"
              onClick={onPreviousItem}
              disabled={disableArrow}
            >
              <ArrowLeft />
            </S.FooterArrowButton>
            <Button
              className="home__button"
              aria-label={selectedItem?.buttonText || "View Live Drop"}
              onClick={handleClick}
            >
              {selectedItem?.buttonText || "View Live Drop"}
            </Button>
            <S.FooterArrowButton
              className="home__footer-arrow-button"
              aria-label="Next Nft on carroussel"
              onClick={onNextItem}
              disabled={disableArrow}
            >
              <ArrowRight className="home__arrow-button" />
            </S.FooterArrowButton>
          </S.FooterNav>
          <MediaMatch lessThan="medium" className="home__media-match">
            <TimerBox availableAt={selectedItem?.availableAt} />
            <DescriptionBox
              shouldAnimate={isSizeLoaded && !isMobile}
              description={selectedItem?.description}
            />
          </MediaMatch>
          <S.OverlayColor className="home__hero overlay" />
          {background && (
            <BackgroundMediaComponent
              objectUrl={background.objectUrl}
              objectType={background.objectType}
            />
          )}
        </S.ContentWrapper>
      </S.HeroWrapper>
      {featuredCollection && (
        <FeaturedCollection {...featuredCollection} domain={domain} />
      )}
      <Footer
        footer={footer}
        logoUrl={logoUrl}
        configuration={configuration}
        organizationName={organizationName}
      />
      <Script src="//code.tidio.co/1tw64nej7vg9c69f56afwkrm3pn3cl9r.js"></Script>
    </S.PageContainer>
  );
};

const TimerBox = ({ availableAt }: Pick<FeaturedItem, "availableAt">) => {
  if (!availableAt) {
    return null;
  }

  const isNftAvailable =
    new Date().getTime() >= new Date(availableAt).getTime();
  return (
    <S.TimerInfo className="home__timer-info">
      {/* {isNftAvailable && <S.TimerTitle>AVAILABLE NOW!</S.TimerTitle>} */}
      {!isNftAvailable && (
        <>
          <S.TimerTitle className="home__timer-title" aria-hidden="true">
            Dropping In
          </S.TimerTitle>
          <TimeCounter date={new Date(availableAt)}>
            {(days, hours, minutes, seconds) => (
              <S.TimerValue
                className="home__time-value"
                aria-label={`Countdown for dropping in ${days} days, ${hours} hours, ${minutes} minutes`}
              >
                <div aria-hidden="true" className="home__timer-value-box">
                  {days}
                  <span aria-hidden="true" className="home__timer-value-unit">
                    d
                  </span>{" "}
                  {hours}
                  <span aria-hidden="true" className="home__timer-value-unit">
                    h
                  </span>{" "}
                  {minutes}
                  <span aria-hidden="true" className="home__timer-value-unit">
                    m
                  </span>{" "}
                  {seconds}
                  <span aria-hidden="true" className="home__timer-value-unit">
                    s
                  </span>
                </div>
              </S.TimerValue>
            )}
          </TimeCounter>
        </>
      )}
    </S.TimerInfo>
  );
};

type DescriptionBoxProps = Pick<FeaturedItem, "description"> & {
  shouldAnimate: boolean;
};

const DescriptionBox = ({
  description,
  shouldAnimate,
}: DescriptionBoxProps) => {
  if (!description) {
    return null;
  }

  return (
    <S.DescriptionInfo
      {...(shouldAnimate && slideFromRight)}
      className="home__description-info"
    >
      <span className="home__description-info-label">Description</span>
      <p className="home__description-info-text">{description}</p>
    </S.DescriptionInfo>
  );
};

export default Home;
