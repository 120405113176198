export const slideFromLeft = {
  initial: { x: "-100vw", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  transition: { duration: 1.5, type: "spring" },
  exit: { x: "-100vw", opacity: 0, transition: { duration: 0.5 } },
};

export const slideFromRight = {
  initial: { x: "100vw", opacity: 0 },
  animate: { x: 0, opacity: 1 },
  transition: { duration: 2, type: "spring" },
  exit: { x: "100vw", opacity: 0, transition: { duration: 0.5 } },
};

export const fadeIn = {
  initial: { opacity: 0, height: "100%" },
  animate: { opacity: 1 },
  transition: { duration: 1, ease: "easeInOut" },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

export const fadeInAndGrow = {
  initial: { height: "1%" },
  animate: { height: "100%" },
  transition: { duration: 1, ease: "easeInOut" },
  exit: { height: "1%", transition: { duration: 0.7, ease: "easeInOut" } },
};
