import * as GridStyles from "components/GridSection/styles";
import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium} ${theme.spacings.xsmall};

    ${media.greaterThan("medium")`
      padding: 6.4rem ${theme.spacings.large};
    `}

    ${GridStyles.Wrapper} {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: ${theme.spacings.xlarge};

    ${media.greaterThan("medium")`
    margin-bottom: 6.4rem;
  `}
  `}
`;

export const Description = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    text-align: center;
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.spacings.large};
    letter-spacing: 0.0025em;
    max-width: 1024px;
  `}
`;

export const FiltersContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    padding-bottom: ${theme.spacings.xxsmall};
    border-bottom: 1px solid #dbdbdb;
  `}
`;
