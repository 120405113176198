import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Video = styled.video``;

export const BackgroundVideo = styled.video`
  display: none;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(10px);
  transform: scale(1.15);
  z-index: 1 !important;

  ${media.greaterThan("medium")`
    display: block;
  `}
`;

type BackgroundImageProps = {
  url: string;
};

export const BackgroundImage = styled.div<BackgroundImageProps>`
  ${({ url }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1 !important;
    filter: blur(30px);
    background-image: url(${url});
    background-position: center center;
    background-size: cover;
  `}
`;
