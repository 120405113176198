import * as HeaderStyles from "components/Header/styles";
import { motion } from "framer-motion";
import styled, { css, keyframes } from "styled-components";
import media from "styled-media-query";

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${media.between("small", "large")`
  //this style is applied only to safari
  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) {
      min-height: fit-content;
      height: 100%;
    }
  }
  `}

  @media(max-height: 786px) {
    height: unset;
  }

  header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .hero-content-wrapper {
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    ${HeaderStyles.Wrapper} {
      margin-bottom: ${theme.spacings.small};
    }

    > * {
      z-index: 3;
    }

    padding: 112px ${theme.spacings.xsmall} ${theme.spacings.small};

    ${media.greaterThan("medium")`
      padding: 200px ${theme.spacings.large} ${theme.spacings.large};
    `}
  `}
`;

export const Title = styled(motion.h1)`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${theme.spacings.medium};
    line-height: ${theme.spacings.medium};
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;

    text-align: left;
    margin-bottom: ${theme.spacings.small};

    ${media.greaterThan("medium")`
      text-align: center;
      word-break: break-word;
    `}

    ${media.greaterThan("large")`
      font-size: 6.4rem;
      line-height: 6.4rem;
      text-align: left;
      margin-bottom: ${theme.spacings.xxlarge};
      }
    `}
  `}
`;

export const TimerInfo = styled.div`
  ${({ theme }) => css`
    text-align: left;
    margin-bottom: ${theme.spacings.medium};

    ${media.greaterThan("large")`
      margin-bottom: 0;
    `}
  `}
`;

export const TimerTitle = styled.span`
  ${({ theme }) => css`
    display: block;
    text-transform: uppercase;
    color: ${theme.colors.lightGray};
    font-size: ${theme.font.sizes.xsmall};
    line-height: ${theme.spacings.xsmall};
    letter-spacing: 0.2em;
    margin-bottom: ${theme.spacings.xxsmall};

    ${media.greaterThan("large")`
      font-size: ${theme.font.sizes.small};
      margin-bottom: 1.2rem;
    `}
  `}
`;

export const TimerValue = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.xlarge};
    line-height: ${theme.spacings.small};
    letter-spacing: 0.005em;

    span {
      text-transform: uppercase;
      letter-spacing: 0.005em;
      font-size: ${theme.font.sizes.xsmall};
      line-height: ${theme.spacings.xsmall};
    }

    ${media.greaterThan("large")`
      font-size: 3.6rem;
      line-height: ${theme.spacings.xlarge};

      span{
        font-size: ${theme.font.sizes.large};
        line-height: ${theme.spacings.small};
      }
    `}
  `}
`;

export const DesktopProgressBar = styled.div`
  ${({ theme }) => css`
    display: none;
    align-items: center;
    margin-bottom: 2rem;
    letter-spacing: 0.2em;

    div {
      display: flex;
      align-items: center;
      margin: 0 ${theme.spacings.xxsmall};
      width: 100%;
      max-width: 7.3rem;
      height: 2px;
      background-color: ${theme.colors.white};
    }

    span {
      font-weight: 500;
      font-size: ${theme.font.sizes.xsmall};
      line-height: 1.6rem;
    }

    ${media.greaterThan("large")`
      display: flex;
    `}
  `}
`;

export const NftInfoContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;

  ${media.greaterThan("large")`
    align-self: flex-start;
  `}
`;

export const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 42rem;
  z-index: 3;
`;

export const MediaWrapper = styled(motion.div)`
  height: 100%;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.greaterThan("medium")`
    max-height: 45rem;
  `}

  @media(max-width: 1280px) {
    video,
    img {
      object-fit: contain;
      max-height: 100%;
      width: 100%;
      box-shadow: 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    }
  }

  @media (min-width: 1280px) {
    video,
    img {
      object-fit: fill;
      height: 50rem;
      width: 100%;
      box-shadow: 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    }
  }

  @media (min-width: 1600px) {
    video,
    img {
      object-fit: fill;
      height: 70rem;
      width: 100%;
      box-shadow: 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    }
  }
`;

export const DescriptionInfo = styled(motion.div)`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings.small};
    border: 1px solid ${theme.colors.lightGray};
    color: ${theme.colors.white};

    span {
      display: block;
      text-transform: uppercase;
      font-weight: 500;
      font-size: ${theme.font.sizes.small};
      line-height: ${theme.spacings.xsmall};
      margin-bottom: {theme.spacings.xsmall}
      letter-spacing: 0.2em;
      margin-bottom: 1.2rem;
    }

    p{
      font-size: ${theme.font.sizes.large};
      line-height: ${theme.spacings.large};
      letter-spacing: 0.0025em;
    }
  `}
`;

export const GridContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  ${media.greaterThan("medium")`
    justify-items: center;
  `}

  ${media.greaterThan("large")`
    min-height: 49rem;
    grid-template-columns: minmax(300px, 1fr) 1fr minmax(300px, 1fr);
    align-items: flex-end;
  `}

  &:focus {
    outline: none;
  }
`;

type FooterArrowButtonProps = {
  disabled?: boolean;
};

export const FooterArrowButton = styled.button<FooterArrowButtonProps>`
  ${({ theme, disabled }) => css`
    color: ${theme.colors.white};
    background-color: transparent;
    border: none;
    cursor: pointer;
    border: 1px solid ${theme.colors.gray};
    border-radius: 50%;
    height: 3.2rem;
    min-width: 3.2rem;

    svg {
      height: 1.6rem;
      width: 1.6rem;
    }

    transition: filter ${theme.transition.default};
    &:hover {
      filter: brightness(0.9);
    }

    ${media.greaterThan("medium")`
      height: 4.8rem;
      width: 4.8rem;

      svg {
        height: 2.4rem;
        width: 2.4rem;
      }
    `}

    ${disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  `}
`;

export const FooterNav = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacings.medium} 0;

    ${media.greaterThan("large")`
      padding: 0;
    `}

    ${FooterArrowButton}:first-child {
      margin-right: ${theme.spacings.xsmall};
    }

    ${FooterArrowButton}:last-child {
      margin-left: ${theme.spacings.xsmall};
    }
  `}
`;

export const OverlayColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    122.62deg,
    rgba(76, 42, 120, 0.8) 12.72%,
    rgba(0, 0, 0, 0.65) 88.97%
  );
`;

const progress = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

interface ProgressBarProps {
  duration: number; //ms
}

export const ProgressBar = styled.div<ProgressBarProps>`
  ${({ duration }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background: linear-gradient(
      270deg,
      #e41c38 0%,
      rgba(228, 28, 56, 0.5) 100%
    );
    animation: ${progress} ${duration}ms ease-in-out;
    animation-fill-mode: both;
    -webkit-animation: ${progress} ${duration}ms ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation: ${progress} ${duration}ms ease-in-out;
    -moz-animation-fill-mode: both;
  `}
`;
