/* eslint-disable react-hooks/exhaustive-deps */
import { allFilters } from "components/Filter/utils";
import FilterAndSort from "components/FilterAndSort";
import GridSection from "components/GridSection";
import NftCard, { NftCardProps } from "components/NftCard";
import { Collection } from "domain/collection/types";
import { api } from "lib/api";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { useEffect, useRef, useState } from "react";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { sanitizeToHtml } from "utils/sanitizeHtml";

import * as S from "./styles";

export type FeaturedColletionProps = Collection & {
  nfts: NftCardProps[];
};

export type FeaturedCollectionComponentProps = FeaturedColletionProps & {
  domain?: string;
};

const FeaturedCollection = ({
  desc,
  nfts,
  domain,
}: FeaturedCollectionComponentProps) => {
  const { query } = useRouter();
  const [items, setItems] = useState(nfts);
  const didMount = useRef(false);
  const PROTOCOL = (process.env.PROD && "https://") || "http://";

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    getCollectionItems(query);
  }, [query]);

  const getCollectionItems = async (query: ParsedUrlQuery) => {
    try {
      const { data = {} } = await api.get(`collections/featured`, {
        params: query,
      });
      setItems(data.nfts);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <S.Wrapper className="featured-collection">
      <S.DescriptionContainer className="featured-collection__description-container">
        <S.Description
          className="featured-collection__description"
          dangerouslySetInnerHTML={sanitizeToHtml(desc)}
        />
      </S.DescriptionContainer>
      <S.FiltersContainer className="featured-collection__filter-options">
        <FilterAndSort items={allFilters} />
      </S.FiltersContainer>
      <GridSection>
        {items.map((nft) => (
          <NftCard
            key={nft._id}
            {...nft}
            qrCode={domain && `${PROTOCOL}${domain}/nft/${nft.slug}`}
          />
        ))}
      </GridSection>
    </S.Wrapper>
  );
};

export default trackWindowScroll(FeaturedCollection);
