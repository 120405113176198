import styled, { css } from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.colors.red};
    background: ${theme.colors.white};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.medium};
    line-height: ${theme.spacings.small};
    padding: 1.2rem 0;
    width: 100%;
    border: 0;
    border-radius: ${theme.border.radius};
    transition: filter ${theme.transition.default};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);

    &:hover {
      filter: brightness(0.9);
    }

    ${media.greaterThan("medium")`
      padding: 2rem 0;
      font-size: ${theme.font.sizes.large};
      line-height: ${theme.spacings.xsmall};
      max-width: 37rem;
    `}
  `}
`;
