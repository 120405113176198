/* eslint-disable @next/next/no-img-element */
import { NftDraftObjectType } from "domain/nftDraft/types";
import s3ToCloudFront from "utils/s3ToCloudFront";

import * as S from "./styles";

type MediaComponentProps = {
  objectUrl: string;
  objectType: NftDraftObjectType;
  title?: string;
};

export const MediaComponent = ({
  objectType,
  objectUrl,
  title,
}: MediaComponentProps) => {
  return (
    <>
      {objectType === "video" && (
        <S.Video
          className="media__video"
          title={`Video NFT ${title}`}
          src={s3ToCloudFront(objectUrl)}
          loop
          playsInline
          autoPlay
          muted
        />
      )}
      {(objectType === "image" || objectType === "gif") && (
        <img
          src={s3ToCloudFront(objectUrl)}
          alt={`Image NFT ${title}`}
          className="media__image"
        />
      )}
    </>
  );
};

export const BackgroundMediaComponent = ({
  objectType,
  objectUrl,
}: MediaComponentProps) => {
  return (
    <>
      {objectType === "video" && (
        <S.BackgroundVideo
          src={s3ToCloudFront(objectUrl)}
          loop
          playsInline
          autoPlay
          muted
          className="media__background-video"
        />
      )}
      {(objectType === "image" || objectType === "gif") && (
        <S.BackgroundImage
          url={s3ToCloudFront(objectUrl)}
          className="media__background-image"
        />
      )}
    </>
  );
};
